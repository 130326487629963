@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@300;400;500;600;700&family=Outfit:wght@300;400;500;600;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@300;400;500;600;700&family=Outfit:wght@300;400;500;600;800&family=Poppins:wght@300;400;500;600;700;800;900&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Glory:wght@200;300;400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:opsz,wght@9..40,200;9..40,300;9..40,400;9..40,500;9..40,600;9..40,700;9..40,800&display=swap');
* {
  margin: 0;
  padding: 0;
}
body {
  font-family: "Manrope";
  width: 100%;
  overflow-x: hidden;
}
header,
footer,
.containar {
  padding: 0 6rem;
}
 #menu-toggle:checked + #menu {
        display: block;
        transform: translateY(0);
      }
      #menu {
  transition: transform 0.3s ease-in-out; /* Adjust the transition duration and easing as needed */
  transform: translateY(-150%);
}

#menu:checked + #menu-container #menu {
  transform: translateY(0);
}
#menu li{
  text-align: left;
  
}
#menu li a{
  text-align: left;
  padding-left: 2rem;
}

.navitems .active {
  border-bottom: 3px solid #05e0d5;
}
.integrate svg {
  width: 100%;
}
.hero_container svg {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.elipse_1 {
  position: absolute;
  width: 273.88px;
  height: 273.88px;
  left: 0.5%;
  flex-shrink: 0;
  border-radius: 273.88px;
  opacity: 0.5;
  background: #0742a6;
  filter: blur(232.5px);
}
.elipse_2 {
  position: absolute;
  width: 260.186px;
  height: 260.186px;
  flex-shrink: 0;
  border-radius: 260.186px;
  opacity: 0.5;
  right: 2%;
  top: 7%;
  background: #181066;
  filter: blur(215px);
}
.not_centered {
  transform: scale(0.9);
  opacity: 0.7;
  padding: 17px;
  background: #fff;
}

/* Non-centered items */
.slick-center {
  opacity: 1;
  transform: scale(1.01);
  background: #fff;
  box-shadow: 0px 6px 40px 0px rgba(27, 72, 90, 0.06);
}
/* .hero_container svg {
  width: 100%;
  height: 100%;
} */

#add_to_cart {
  animation: 20s linear infinite addCart;
}

#cart {
  animation: 20s linear infinite cartSect;
}
#loading_card {
  animation: 20s linear infinite loadingCard;
}
#proceed {
  animation: 20s linear infinite cartCardBtn;
}
#continue_payment {
  animation: 20s linear infinite adressBtn;
}
#address {
  animation: 20s linear infinite adressCard;
}
#method_card {
  animation: 20s linear infinite methodCard;
}
#method_select {
  animation: 20s linear infinite methodCardSlect;
}
#method_select_all {
  animation: 20s linear infinite methodCardSlectall;
}
#method_btn {
  animation: 20s linear infinite methodBtn;
}
#loading_spiner {
  animation: 20s linear infinite loadingCardSpinner;
}
#final_step {
  animation: 20s linear infinite finalStep;
}
#qr_card {
  animation: 20s linear infinite qrCard;
}
#success_card {
  animation: 20s linear infinite successCard;
}
#scaner {
  animation: 20s linear infinite qrCardDownScanner;
  position: relative;
}
#add_to_cart,
#loading_card,
#add_to_cart,
#proceed,
#continue_payment,
#address,
#method_card,
#method_select,
#method_select_all,
#method_btn,
#loading_spiner {
  transform-origin: center;
  transform-box: fill-box;
}

@keyframes scanningBackground {
  0% {
    background-position: 100% 0;
  }

  100% {
    background-position: -100% 0;
  }
}
@keyframes addCart {
  2%,
  4% {
    transform: scaleX(1.01) scaleY(1.02);
  }
  3% {
    transform: scaleX(0.8) scaleY(0.8);
  }
}
/* cart section */
@keyframes cartSect {
  0%,
  100%,
  20%,
  8% {
    opacity: 0;
  }

  10%,
  18% {
    opacity: 1;
  }
}
@keyframes cartCardBtn {
  14%,
  16% {
    transform: scaleX(1) scaleY(1);
  }

  15% {
    transform: scaleX(0.8) scaleY(0.8);
  }
}
@keyframes adressBtn {
  24%,
  26% {
    transform: scaleX(1) scaleY(1);
  }
  25% {
    transform: scaleX(0.8) scaleY(0.8);
  }
}
@keyframes adressCard {
  0%,
  100%,
  18%,
  28.5%,
  30% {
    opacity: 0;
  }

  20%,
  28% {
    opacity: 1;
  }
}
@keyframes methodCard {
  0%,
  100%,
  28%,
  76% {
    opacity: 0;
  }

  29.5%,
  74% {
    opacity: 1;
  }
}
@keyframes methodCardSlect {
  0%,
  100%,
  28%,
  76% {
    opacity: 0;
  }

  35.5%,
  74% {
    opacity: 1;
  }
}
@keyframes methodCardSlectall {
  33%,
  34% {
    transform: scaleX(1) scaleY(1);
  }
  33% {
    transform: scaleX(0.8) scaleY(0.8);
  }
}
@keyframes methodBtn {
  40%,
  42% {
    transform: scaleX(1) scaleY(1);
  }

  41% {
    transform: scaleX(0.8) scaleY(0.8);
  }
}

@keyframes loadingCard {
  0%,
  100%,
  26%,
  30%,
  74%,
  84% {
    opacity: 0;
  }
  28%,
  29.5%,
  76%,
  82% {
    opacity: 1;
  }
}
@keyframes loadingCardSpinner {
  0%,
  100%,
  74%,
  88% {
    opacity: 0;
  }

  76% {
    opacity: 1;
    transform: rotate(0);
  }
  80% {
    opacity: 1;
    transform: rotate(360deg);
  }
  84% {
    opacity: 1;
    transform: rotate(720deg);
  }
  86% {
    opacity: 1;
    transform: rotate(900deg);
  }
}
@keyframes finalStep {
  0%,
  100%,
  82%,
  98% {
    opacity: 0;
  }

  84%,
  96% {
    opacity: 1;
  }
}
@keyframes qrCard {
  0%,
  100%,
  42%,
  68% {
    opacity: 0;
  }

  44%,
  66% {
    opacity: 1;
  }
}
@keyframes successCard {
  0%,
  100%,
  66%,
  82% {
    opacity: 0;
  }

  68%,
  80% {
    opacity: 1;
  }
}
@keyframes qrCardDownScanner {
  0%,
  48%,
  100% {
    opacity: 0;
    transform: translateY(0);
  }

  50%,
  52%,
  96% {
    opacity: 1;
    transform: translateY(0);
  }

  54%,
  69% {
    opacity: 1;
    transform: translateY(6rem);
  }

  60%,
  98% {
    opacity: 1;
    transform: translateY(0rem);
  }
}
@keyframes qrCardUpScanner {
  0%,
  52%,
  62%,
  96% {
    opacity: 0;
  }

  54% {
    opacity: 1;
    transform: translateY(0);
  }
  56%,
  59% {
    opacity: 1;
  }
  60%,
  98% {
    opacity: 0;
    transform: translateY(25rem);
  }
  100% {
    opacity: 0;
    transform: translateY(0);
  }
}

/* faq css */

.styles_icon-wrapper__2cftw {
  font-size: 36px !important;
  color: #05e0d5 !important;
  transform: rotate(0deg) !important;
}
.styles_faq-row__2YF3c {
  border-bottom: 1px solid #05e0d5 !important;
}
.wave_container {
  height: 31rem;
}
.wave {
  transform: rotate(7deg);
  bottom: 6rem;
}
.second {
  top: 20.2vh;
}
.third {
  top: 6.9vw;
  right: 23vw;
}
.fourth {
  top: -9vh;
  right: 5vw;
}
.curve_bg {
  height: 72vh;
  width: 100vw;
}
.straight{
  display: none;
}
.hero_container_text{
  margin-top: 4rem;
}
@media (max-width: 1650px) {
  .wave_container {
    height: 26.5rem;
  }
  .wave {
    transform: rotate(6deg);
    bottom: 8rem;
  }
  .fourth {
    right: 3vw;
  }
  .third {
    
    right: 21vw;
  }
  .hero_container_text{
    margin-top: 0rem;
  }
}
@media (max-width: 1500px) {
  .wave_container {
    height: 24.5rem;
  }
  .wave {
    transform: rotate(5deg);
  }
  .third {
    right: 20vw;
  }
  .fourth {
    right: 1vw;
  }
}
@media (max-width: 1400px) {
  .wave {
    transform: rotate(4deg);
    bottom: 9rem;
  }
  .second {
    top: 15.2vh;
  }

  .fourth {
    right: 0vw;
    top: -9vh;
  }
  .third {
    top: 4.9vw;
    right: 18vw;
  }

  .curve_bg {
    height: 92vh;
  }
}
@media (max-width: 1250px) {
  .wave{
    display: none;
  }
  .wave_container{
    display: flex;
    flex-direction: column;
    padding-left: 22px;
    height: auto;
  }
  .one, .second, .third, .fourth {
    position: static;
    width: 100%;
    height: 191px;
    margin-bottom: 35px; 
  }
  .fourth div{
    margin-left: 0;
  }
  .straight{
    display: flex;
    left: -7%;
  }
  .curve_bg{
    height: auto;
  }
.bg_image{
  background: #fff;
  background-image: none !important;
}
}
@media (max-width: 950px) {
  .straight{

    left: -8%;
  }
 
}
@media (max-width: 750px) {
  .straight{
    left: -11%;
  }
  .wave_container{
    margin-top: 3rem;
  }
  header,
  footer,
  .containar {
    padding: 0 3rem;
  }
  .logo{
    width: 150px;
  }
}

/* dfdsngsdn */
@media (max-width: 480px) {
  header,
  footer,
  .containar {
    padding: 0 1.5rem;
  }

  .straight{
    left: -2%;
  }
  .wave_container{
    padding-left: 17%;
  }
  .crosal_iamge svg{
    width: 45px;

  }
}
